<template>
    <div class="Partners" id="PartnersPage">
        <div class="Trigger TriggerTop" id="PartnersTriggerTop"></div>
        <div class="PartnersPanel" id="Partners">
            <header class="PartnersPanel-content">
                <div class="Partners-text">
                    <h2 class="subtitle Partners-subtitle">Our partners</h2>
                </div>
            </header>
        </div>
        <div class="Partners-logos">
            <figure
                    :class="`Partners-logo_${partner.key}`"
                    :key="index"
                    :title="partner.name"
                    v-for="(partner, index) in ourPartners">
                <a :aria-label="partner.name" :class="`Partners-link_${partner.key}`" :href="partner.website" rel="noreferrer" target="_blank" v-if="partner.website">
                    <img :alt="partner.name" :src="logoFile(partner.key)" />
                </a>
                <img :alt="partner.name" :src="logoFile(partner.key)" v-else />
            </figure>
        </div>
        <div class="Trigger TriggerBottom" id="PartnersTriggerBottom"></div>
    </div>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'Partners',
        data() {
            return {
                publicPath: process.env.BASE_URL,
                ourPartners: store.state.ourPartners
            };
        },
        mounted() {
        },
        methods: {
            normalizedName(brandName) {
                return `${brandName}`.replace(/\s+/g, '');
            },
            logoFile(key) {
                return `${this.publicPath}img/clients/${key}.jpg`;
            }
        }
    };
</script>
